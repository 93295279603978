import React, { useEffect, useState } from 'react';
import useAuth from '../../contexts/UseAuth';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FansFundMeSocialCallback } from '@fans-fund-me/storybook';
import currencies from '../../utilities/currencies.json';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const SocialCallback = (): React.ReactElement => {
  const auth = useAuth();
  const navigate = useNavigate();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const [userCurrency, setUserCurrency] = useState(
    auth.currency !== null ? auth.currency : 'USD'
  );

  const updateUserCurrency = (currency: string) => {
    setUserCurrency(currency);
    localStorage.setItem('ffm_currency', currency);
  };

  useEffect(() => {
    axios
      .put(`${process.env.REACT_APP_API_ENDPOINT}/x-auth/user/${auth.userID}`, {
        state: query.get('state'),
        code: query.get('code'),
      })
      .then((_) => {
        setIsLoading(false);
      })
      .catch((_) => {
        setIsError(true);
        setIsLoading(false);
      });
  }, [auth.userID, query]);

  return (
    <FansFundMeSocialCallback
      navbarItems={[]}
      acceptedCurrencies={currencies}
      userCurrency={userCurrency}
      setUserCurrency={updateUserCurrency}
      onClick={() => navigate('/account-settings')}
      isLoading={isLoading}
      isError={isError}
      cookieBannerIsVisible={auth.cookies === null}
      onClickAcceptCookies={auth.acceptCookies}
      onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
    />
  );
};
