import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Checkout } from './Checkout';
import axios from 'axios';
import { FansFundMeCheckoutScreen } from '@fans-fund-me/storybook';
import currencies from '../../utilities/currencies.json';
import useAuth from '../../contexts/UseAuth';
import { useNavigate } from 'react-router-dom';

export const CheckoutHOC = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [clientSecret, setClientSecret] = useState('');
  const [item, setItem] = useState({
    id: '',
    name: '',
    image: '',
    price: '',
    allowRepeatPurchases: false,
  });
  const [currency, setCurrency] = useState('');
  const [serviceCharge, setServiceCharge] = useState('');
  const [currencyConversionFee, setCurrencyConversionFee] = useState('');
  const [total, setTotal] = useState('');
  const [itemPrice, setItemPrice] = useState('');

  const [creatorName, setCreatorName] = useState('');
  const [creatorProfilePicture, setCreatorProfilePicture] = useState('');

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

  const [singlePaymentGiftId, setSinglePaymentGiftId] = useState('');

  useEffect(() => {
    const profileUserID = localStorage.getItem('ffm_checkout_user');
    const itemID = localStorage.getItem('ffm_checkout_item');
    const userCurrency = localStorage.getItem('ffm_currency');
    const isSurpriseGift = localStorage.getItem('ffm_checkout_surprise_gift');
    // const surpriseGiftId = localStorage.getItem(
    //   'ffm_checkout_surprise_gift_id'
    // );
    const surpriseGiftPaymentId = localStorage.getItem(
      'ffm_checkout_surprise_gift_payment_id'
    );
    const surpriseGiftAmount = localStorage.getItem(
      'ffm_checkout_surprise_gift_amount'
    );
    const surpriseGiftMessage = localStorage.getItem(
      'ffm_checkout_surprise_gift_message'
    );

    // const surpriseGiftPayload: {
    //   id: string | null;
    //   // suggestion: string | null;
    //   // senderAmount: string | null;
    // } = {
    //   id: surpriseGiftId,
    //   // suggestion: surpriseGiftMessage,
    //   // senderAmount: surpriseGiftAmount,
    // };

    const singlePaymentPayload: {
      receiverId: string | null;
      wishListItemId: string | null;
      currency: string | null;
    } = {
      receiverId: profileUserID,
      wishListItemId: itemID,
      currency: userCurrency,
    };

    const axiosConfig =
      auth.token === null
        ? {}
        : { headers: { Authorization: `Bearer ${auth.token}` } };

    if (isSurpriseGift !== null && isSurpriseGift === 'true') {
      axios
        .get(
          `${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/singlepayment/${surpriseGiftPaymentId}`,
          axiosConfig
        )
        .then((response) => {
          setSinglePaymentGiftId(surpriseGiftPaymentId!);
          setClientSecret(response.data.stripeSecret);
          setItem({
            id: '',
            name: response.data.item.name,
            image: response.data.item.image,
            price: '',
            allowRepeatPurchases: false,
          });
          setTotal(response.data.senderAmount);
          setItemPrice(response.data.senderSubtotal);
          setServiceCharge(response.data.serviceCharge);
          setCurrencyConversionFee(response.data.conversionFee);
          setCurrency(response.data.senderCurrency);
          setCreatorName(response.data.sellerName);
          setCreatorProfilePicture(response.data.sellerProfilePic);
        })
        .catch((error) => {
          const checkoutUsername = localStorage.getItem(
            'ffm_checkout_username'
          );

          if (error.response?.status === 403) {
            auth.logout();
            navigate('/login');
          } else if (checkoutUsername) {
            navigate(`/${checkoutUsername}`);
          } else {
            navigate(`/${auth.username}`);
          }
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT_PAYMENTS}/singlepayment`,
          singlePaymentPayload,
          axiosConfig
        )
        .then((response) => {
          setSinglePaymentGiftId(response.data.id);
          setClientSecret(response.data.stripeSecret);
          setItem(response.data.item);
          setTotal(response.data.senderAmount);
          setItemPrice(response.data.senderSubtotal);
          setServiceCharge(response.data.serviceCharge);
          setCurrencyConversionFee(response.data.conversionFee);
          setCurrency(response.data.senderCurrency);
          setCreatorName(response.data.sellerName);
          setCreatorProfilePicture(response.data.sellerProfilePic);
        })
        .catch((err) => {
          const checkoutUsername = localStorage.getItem(
            'ffm_checkout_username'
          );
          navigate(`/${checkoutUsername}`);
        });
    }
  }, [auth, navigate]);

  if (clientSecret === '') {
    return (
      <FansFundMeCheckoutScreen
        navbarItems={[]}
        authUser={undefined}
        acceptedCurrencies={currencies}
        giftAnonymously={true}
        setGiftAnonymously={() => {}}
        itemImage={item.image}
        itemName={item.name}
        itemPrice={itemPrice}
        serviceFee={serviceCharge}
        conversionFee={currencyConversionFee}
        total={total}
        creatorName={creatorName}
        creatorProfilePicture={creatorProfilePicture}
        email={''}
        setEmail={() => {}}
        expressCheckoutVisible={false}
        message={''}
        setMessage={() => {}}
        agreeTerms={false}
        setAgreeTerms={() => {}}
        userCurrency={'GBP'}
        errorMessage={''}
        handleSubmit={() => {}}
        handleCashAppButton={() => {}}
        isLoading={true}
        paymentIsLoading={false}
        cookieBannerIsVisible={auth.cookies === null}
        onClickAcceptCookies={auth.acceptCookies}
        onClickAcceptNecessaryCookies={auth.acceptNecessaryCookies}
      />
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        // mode: 'payment',
        // currency: 'usd',
        // amount: 1099,
        clientSecret: clientSecret,
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Work+Sans',
          },
        ],
        appearance: {
          theme: 'night',
          variables: {
            colorPrimary: '#0570de',
            colorBackground: '#2f3032',
            spacingUnit: '0.25rem',
            fontFamily: 'Work Sans',
            borderRadius: '0.25rem',
            spacingGridColumn: '2rem',
            spacingGridRow: '2rem',
            spacingTab: '2rem',
          },
          rules: {
            '.Tab': {
              margin: '1rem',
              marginBottom: '0',
            },
            '.TabLabel': {
              paddingTop: '0.5rem',
            },
            '.Input': {
              borderColor: '#2f3032',
              boxShadow: '0',
            },
            '.Block': {
              backgroundColor: '#171717',
              border: '#171717',
              boxShadow: '0',
              paddingTop: '1rem',
              paddingBottom: '1.5rem',
            },
          },
        },
      }}
    >
      <Checkout
        singlePaymentGiftId={singlePaymentGiftId}
        item={item}
        itemPrice={itemPrice}
        serviceCharge={serviceCharge}
        currencyConversionFee={currencyConversionFee}
        total={total}
        currency={currency}
        creatorName={creatorName}
        creatorProfilePicture={creatorProfilePicture}
      />
    </Elements>
  );
};
